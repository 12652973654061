import React, { useState, useMemo, useCallback } from 'react';
import { Dialog } from 'factor';
import { COMMON_DIALOG_MODAL_PROPS } from 'constants/audiences';
import { InsertionOrderBasicOption as IOOption } from 'models/InsertionOrder';
import { SelectInsertionOrder } from './SelectInsertionOrder';
import styles from './styles.module.scss';
import { SelectedInsertionOrderTable } from './SelectedInsertionOrderTable';

interface Props {
  onClose: () => void;
  onConfirm: (ioId: number) => void;
}

export const CreateCampaignDialog = (props: Props) => {
  const { onClose, onConfirm } = props;
  const [selectedInsertionOrders, setSelectedInsertionOrders] = useState<IOOption[]>([]);

  const actionButtons = useMemo(
    () => [
      {
        variant: 'secondary',
        title: 'Cancel',
        handler: onClose,
      },
      {
        variant: 'primary',
        title: 'Create Campaign',
        handler: selectedInsertionOrders?.length
          ? () => onConfirm(selectedInsertionOrders[0].ioId)
          : undefined,
        disabled: !selectedInsertionOrders?.length,
      },
    ],
    [onClose, selectedInsertionOrders, onConfirm],
  );

  const handleTableRemove = useCallback(() => {
    setSelectedInsertionOrders([]);
  }, []);

  return (
    <Dialog
      dialogTitle="Create Campaign"
      open
      crossButton
      headerFooterBorders
      onCrossButtonClick={onClose}
      actionButtons={actionButtons}
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      className={styles.dialog}
    >
      <div className={styles.dialogText}>
        Select an Insertion Order to create a campaign using the selected audience(s)
      </div>
      <div className="mb-4">
        <SelectInsertionOrder
          selectedInsertionOrders={selectedInsertionOrders}
          setSelectedInsertionOrders={setSelectedInsertionOrders}
        />
      </div>
      {!!selectedInsertionOrders?.length && (
        <SelectedInsertionOrderTable
          selectedInsertionOrder={selectedInsertionOrders[0]}
          onRemove={handleTableRemove}
        />
      )}
    </Dialog>
  );
};
