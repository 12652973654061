import React, { useMemo, useCallback } from 'react';
import { Table, Icon, Tooltip } from 'factor';
import { BudgetTypeIconMapper, IOStatusMapper } from 'constants/insertionOrder';
import { InsertionOrderBasicOption as IOOption } from 'models/InsertionOrder';
import styles from './styles.module.scss';

interface Props {
  onRemove: () => void;
  selectedInsertionOrder: IOOption;
}

export const SelectedInsertionOrderTable = (props: Props) => {
  const { onRemove, selectedInsertionOrder } = props;

  const tableHeader = useMemo(
    () => [
      { label: 'ID', className: 'w-100-100' },
      {
        label: 'Insertion Order Name',
        className: 'w-200-400',
      },
      {
        label: 'Status',
        className: 'w-150-200',
      },
      { label: 'Action', className: 'w-80-80' },
    ],
    [],
  );

  const tableBody = useMemo(
    () => [
      {
        key: (item: IOOption) => item.ioId,
        className: 'w-100-100',
      },
      {
        key: (item: IOOption) => {
          const budgetIconName = BudgetTypeIconMapper[item.ioBudgetTypeId];

          return (
            <div className={styles.ioIconAndText}>
              {!!budgetIconName && <Icon name={budgetIconName} className={styles.budgetTypeIcon} />}
              <Tooltip label={item.ioName} auto portal className={styles.ioNameTooltip}>
                <div className={`${styles.tableText} pl-2`}>{item.ioName}</div>
              </Tooltip>
            </div>
          );
        },
        className: 'w-200-400',
      },
      {
        key: (item: IOOption) => {
          const { icon, label } = IOStatusMapper[item.ioStatusId] || {};

          return (
            <div className={styles.ioIconAndText}>
              {!!icon && <Icon name={icon} className={styles.statusIcon} />}
              <div className={`${styles.tableText} pl-1`}>{label || '-'}</div>
            </div>
          );
        },
        className: 'w-150-200',
      },
      {
        key: (item: IOOption) => (
          <div onClick={() => onRemove()} className={styles.deleteWrapper} role="button">
            <Icon name="DeleteAlt" />
          </div>
        ),
        className: 'w-80-80',
      },
    ],
    [onRemove],
  );

  const rowKeyExtractor = useCallback((row: IOOption) => row.ioId, []);

  return (
    <Table
      data={[selectedInsertionOrder]}
      header={tableHeader}
      body={tableBody}
      rowKeyExtractor={rowKeyExtractor}
      className={styles.table}
      enableContextMenu={false}
    />
  );
};
