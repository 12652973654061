import React from 'react';
import { LazySelect } from 'iqm-framework';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { InsertionOrderBasicOption as IOOption, InsertionOrderBasic } from 'models/InsertionOrder';
import { Option } from './Option';
import styles from './styles.module.scss';

interface Props {
  setSelectedInsertionOrders: (ios: IOOption[]) => void;
  selectedInsertionOrders: IOOption[];
}

export const SelectInsertionOrder = (props: Props) => {
  const { setSelectedInsertionOrders, selectedInsertionOrders } = props;

  const handleSelection = (selectedOption: IOOption) => {
    if (selectedOption) {
      if ((selectedOption as any).id === selectedInsertionOrders[0]?.id) {
        setSelectedInsertionOrders([]);
      } else {
        setSelectedInsertionOrders([selectedOption]);
      }
    } else {
      setSelectedInsertionOrders([]);
    }
  };

  const transformRequestParams = (params: any) => {
    return {
      pageNo: params.pageNo,
      noOfEntries: params.noOfEntries,
      searchField: params.searchField,
      sortBy: '-ioId',
      ioStatusIdsList: [IO_STATUS_ID.ACTIVE, IO_STATUS_ID.EXPIRED],
    };
  };

  const mapResponseData = (res: any) => {
    return {
      ...res.data,
      data: res.data.ioBasicDetailsList.map((io: InsertionOrderBasic) => ({
        ...io,
        reactLabel: (
          <Option
            statusId={io.ioStatusId}
            id={io.ioId}
            name={io.ioName}
            budgetTypeId={io.ioBudgetTypeId}
          />
        ),
        label: io.ioName || '',
        value: io.ioId,
        id: io.ioId,
      })),
    };
  };

  return (
    <div className={styles.selectWrapper}>
      <LazySelect
        apiPath="api/v3/cmp/io/basic/list"
        key={'createCampaignInsertionOrderSelect'}
        selectSpecificProps={{
          className: styles.select,
          placeholder: 'Select Insertion Order',
          searchPlaceholder: 'Search by ID, Name',
          label: 'Insertion Order',
          isSearchable: true,
          isSearchClearable: true,
          searchByValue: true,
          isMulti: false,
          isClearable: selectedInsertionOrders && selectedInsertionOrders.length,
          hideClearAllButton: !selectedInsertionOrders || !selectedInsertionOrders.length,
          allSelectable: false,
          showLabelAlways: true,
          showControlLabel: true,
          withCheckbox: true,
          hideSelectAllWhenEmpty: true,
          value: selectedInsertionOrders[0]
            ? { ...selectedInsertionOrders[0], reactLabel: null }
            : undefined,
          fitContainer: true,
          underline: true,
        }}
        onChange={handleSelection}
        httpMethod="POST"
        transformPagingParameters={transformRequestParams}
        mapServerResponseData={mapResponseData}
        value={selectedInsertionOrders}
        totalRecordsPropName="filteredRecords"
        numberOfEntries={50}
        useParamsAsBody
        params={{}}
      />
    </div>
  );
};
