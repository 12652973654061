import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { Button } from 'factor';
import { getPathNumber } from 'iqm-framework';
import { Box, Tooltip } from '@applift/factor';
import { CAMPAIGN_MAX_AUDIENCE_COUNT, PRE_BID_AUDIENCE_TYPE_ID } from 'constants/audiences';

import { CreateCampaignDialog } from './CreateCampaignDialog';

import { AppState } from '../../store';

export const CreateCampaignButton = ({ className = '' }: { className?: string }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectedAudiences = useSelector((state: AppState) => state.table.selectedAudiences);

  const selectedAudienceTypeIds = useMemo(
    () => uniq(selectedAudiences.map((i) => i.audienceTypeId)),
    [selectedAudiences],
  );

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const PRE_BID_AUDIENCE_IDS = selectedAudiences
    .filter((item) => item.audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID)
    .map((filteredAudience) => filteredAudience.id);

  const OTHER_AUDIENCE_IDS = selectedAudiences
    .filter((item) => item.audienceTypeId !== PRE_BID_AUDIENCE_TYPE_ID)
    .map((filteredAudience) => filteredAudience.id);

  const handleCreateCampaign = (ioId: number) => {
    const query = {
      typeIds: selectedAudienceTypeIds.join(','),
      audIds: OTHER_AUDIENCE_IDS.join(','),
      preBidIds: PRE_BID_AUDIENCE_IDS.join(','),
    };
    window.open(
      `/campaigns/u/${getPathNumber()}/#/campaign-create?ioId=${ioId}&audTypeIds=${query.typeIds}${
        query.audIds ? `&audIds=${query.audIds}` : ''
      }${query.preBidIds ? `&preBidIds=${query.preBidIds}` : ''}`,
    );
    setIsDialogOpen(false);
  };

  const maxAudiencesExceeded = selectedAudiences.length > CAMPAIGN_MAX_AUDIENCE_COUNT;
  const ineligibleAudienceStatus = selectedAudiences.some((audience) =>
    ['Failed', 'Rejected'].includes(audience.status),
  );

  return (
    <>
      <Box>
        <Tooltip
          title={(() => {
            if (maxAudiencesExceeded)
              return `Maximum limit of ${CAMPAIGN_MAX_AUDIENCE_COUNT} audiences per campaign is reached`;
            if (ineligibleAudienceStatus)
              return 'Cannot add Failed or Rejected audiences to a Campaign';
            return null;
          })()}
        >
          <span>
            <Button
              className={className}
              variant="tertiary2"
              size="large"
              iconName="Campaign Builder"
              onClick={handleClick}
              disabled={maxAudiencesExceeded || ineligibleAudienceStatus}
            >
              Create Campaign
            </Button>
          </span>
        </Tooltip>
      </Box>
      {isDialogOpen && (
        <CreateCampaignDialog
          onClose={() => setIsDialogOpen(false)}
          onConfirm={handleCreateCampaign}
        />
      )}
    </>
  );
};
