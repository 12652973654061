import * as MatchedAudience from './MatchedAudience';
import * as RetargetedAudience from './RetargetedAudience';
import * as SegmentedAudience from './SegmentedAudience';
import * as LookalikeAudience from './LookalikeAudience';
import * as ContextualAudience from './ContextualAudience';
import * as CampaignAudience from './CampaignAudience';
import * as GeofarmedAudience from './GeofarmedAudience';
import * as Table from './Table';
import * as AddToCampaign from './AddToCampaign';
import * as Common from './CommonAPI';
import * as Hubspot from './Hubspot';
import * as Cloud from './Cloud';
import * as NationBuilder from './NationBuilder';

export const API = {
  MatchedAudience,
  RetargetedAudience,
  SegmentedAudience,
  LookalikeAudience,
  ContextualAudience,
  CampaignAudience,
  GeofarmedAudience,
  Table,
  AddToCampaign,
  Common,
  Hubspot,
  Cloud,
  NationBuilder,
};
