export const BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
};

export const BudgetTypeIconMapper = {
  [BUDGET_TYPE_ID.DOLLAR_BASED]: 'InsertionOrderBudgetCurrency',
  [BUDGET_TYPE_ID.IMPRESSIONS_BASED]: 'InsertionOrderBudgetImpression',
};

export const IO_STATUS_ID = {
  ACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
};

export const IOStatusMapper = {
  [IO_STATUS_ID.ACTIVE]: { label: 'Active', icon: 'StatusReadyColor' },
  [IO_STATUS_ID.EXPIRED]: { label: 'Expired', icon: 'StatusExpiredColor' },
  [IO_STATUS_ID.DELETED]: { label: 'Deleted', icon: 'StatusDeletedColor' },
};
