import React from 'react';
import { Icon } from 'factor';
import { BudgetTypeIconMapper, IOStatusMapper } from 'constants/insertionOrder';
import styles from './styles.module.scss';

interface Props {
  id: number;
  name: string;
  budgetTypeId: number;
  disablePadding?: boolean;
  statusId: number;
}

export const Option = (props: Props) => {
  const { id, name, budgetTypeId, disablePadding, statusId } = props;
  const budgetIconName = BudgetTypeIconMapper[budgetTypeId as any];
  const { label: statusLabel, icon: statusIcon } = IOStatusMapper[statusId] || {};

  return (
    <div className={styles.optionWrapper}>
      {!!budgetIconName && <Icon name={budgetIconName} className={styles.budgetTypeIcon} />}
      <div
        className={`select-insertion-order-custom-option ${styles.optionInfoWrapper} ${
          disablePadding ? styles.noPadding : ''
        }`}
      >
        <div className={styles.ioName}>{name}</div>
        <div className={styles.infoRow}>
          <div className={styles.ioId}>
            <span className={styles.idText}>ID</span>
            <span className={styles.idValue}>{id}</span>
          </div>
          <div className={styles.separator} />
          <div className={styles.ioStatus}>
            {!!statusIcon && <Icon name={statusIcon} className={styles.statusIcon} />}
            {statusLabel}
          </div>
        </div>
      </div>
    </div>
  );
};
